*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

/* body {
  height: 100dvh;
  width: 100dvw;
  margin: 0;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #7561fb;
}

.trails-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trails-text {
  margin-top: 10%;
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: rgb(10, 57, 138);
  font-size: 5em;
  font-weight: 800;
  text-transform: none;
  will-change: transform, opacity;
  overflow: hidden;
  justify-content: center;
  text-align: center;
}

.trails-text > div {
  overflow: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* very helpful for debugging css*/
/* * {
  outline: 1px solid red;
} */

.app-container {
  background-image: url('./images/blueprint.jpg');
  width: 100%;
  max-width: '1920px';
}

.homepageAppTitle {
  color: white; 
  padding-top: 5%; 
  font-family:'Courier New', Courier, monospace;
  display: flex; 
  justify-content: center;
  
}

.apolloLogoBackground {
  background: url('./images/apollo-logo80.png');
  height: 80vh;
  width: 100%; /* Adjusted to be relative to the viewport width */ /* Ensures the image covers the whole Box */
  background-position: center; /* Centers the background image */
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: inherit;
  background-repeat: no-repeat;
}

@media (max-width: 1250px) {

  .apolloLogoBackground {
    background: url('./images/apollo-logo50.png'); 
    height: 55vh;/* Adjusted to be relative to the viewport width */ /* Ensures the image covers the whole Box */
    background-position: center; /* Centers the background image */
    display: flex;
    justify-content: center;
    align-items: center;
    background-attachment: inherit;
    background-repeat: no-repeat;
  }
}


.homepageCardText {
  display: flex;
  justify-content: center;
  color: white;
}

.custom-header {
  background-color: lightgrey; /* Set the background color to grey */
  font-weight: bolder;
}

.blue-cell {
  background-color: #355DA5;
}

.green-cell {
  background-color: #6DBD67;
}

.grey-cell {
  background-color: #AEAAAA;
}

/* .name {
  color: #333;
  animation: shadowMove 2s infinite;
}

@keyframes shadowMove {
  0% {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0);
  }
  50% {
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }
  100% {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0);
  }
} */


.purchaseContactImportance {
  font-family: 'Georgia', serif;
  color: #2c3e50;
  text-decoration: underline;
  /*transform: translateX(-100%);
  animation: fadeInFromLeft 2s forwards; */
}

@keyframes fadeInFromLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
